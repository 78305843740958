

$primary: #ff695f;
$secondary: #ffffff;
$enable-validation-icons: false;

$step-content-width: 19rem !default;
$step-gutter: 1.4rem !default;
$step-circle-size: 2.7rem !default;
$step-content-padding-top: 4rem !default;
$step-circle-border-width: 2px !default;

@media print {
  @page { margin: 0; }
  html {
    background: white;
  }
  body {
    margin: 1.6cm;
    background: white;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@import 'node_modules/bootstrap/scss/bootstrap';
@import '~bootstrap-steps/scss/bootstrap-steps';
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-family: Monserat, Helvetica, serif;
  box-sizing: border-box;
  min-height: 100%;
  background: #7FA0B3;
  background-position: center center;
  font-size: 1.6rem;
}
.stop-scrolling{
  overflow-y: hidden !important;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.stop-scrolling::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}
.btn {
  box-shadow: none !important;
}

.btn-link:hover {
  text-decoration: none;
}

body.dropping .materials .uploader .drag-message {
  opacity: 1;
}
.primary-text-color{
  color: $primary !important;
}
iframe {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  border: 0;
  overflow: auto;
  width: 100%;
  background: #fff3f5;
}
.form-lable-small {
  font-size: 1.2rem;
}
.form-control, .btn {
  font-size: 1.6rem;
}
.btn {
  padding: 0.6rem 1.2rem;
}

//noinspection CssInvalidPseudoSelector
*::-webkit-media-controls {
  display: none!important;
  -webkit-appearance: none;
}

//noinspection CssInvalidPseudoSelector
*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}
//noinspection CssInvalidPseudoSelector
*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}
//noinspection CssInvalidPseudoSelector
*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
.DayPickerInput{
  width: 100%;
}
.rc-time-picker-input{
  padding:1.9rem 0 1.9rem 0.5rem;
  font-size:12pt;
  color: #000;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #CED4DA;
  border-color: #CED4DA;
}

.rh5v-DefaultPlayer_component {
  height: 140px !important;
  background-color: transparent !important;
}



.font-15 {
  font-size: 1.5rem !important
}
.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

.react-player{
  width: 220px !important;
  height: 140px !important;
  background: transparent !important;
}
.rh5v-Overlay_inner {
  width: 32px !important;
  height: 32px !important;
  left: 55% !important;
  top: 50% !important;
  background:none;
}
.rh5v-Overlay_inner svg {
  display: none;
}
.rh5v-Overlay_inner:before{
  text-indent: 100%;
  content: '';
  background:url('playicon.png');
  background-size:cover;
  position:absolute;
  width:28px;
  height:28px;
  left: 10% !important;
}
.react-time-picker__clock {
  display: none !important;
}
.react-time-picker__wrapper{
  padding: 0.65rem;
  border: 1px solid rgb(226, 232, 240);
  width: 100%;
  border-radius: 0.25rem;
}
.react-time-picker__inputGroup__hour {
  width: 20px !important;
}
.react-time-picker__inputGroup__minute{
  width: 20px !important;
}
.react-time-picker__inputGroup__input--hasLeadingZero{
  margin-top: 0.2px;
}
.form-check-label{
  margin-left: 4px;
}
.hover:hover {
  background-color: rgb(236, 236, 236);
  }
  @media only screen and (max-width:410px)
  {
    
    .hidden-mobile{
      display: none !important;
    }
  }
@media only screen and (max-width:480px)
{
  .react-time-picker__inputGroup__input--hasLeadingZero{
    margin-top: 1.2px;
  }
  .react-player .rh5v-DefaultPlayer_video {
    width: 220px !important;
    height: unset;
  }
}
@media only screen and (max-width:769px)
  {
  .hidden-xs {
  display: none;
  }
  .accordion .card-header {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .text-right {
    text-align: left !important;
  }
  .center-xs {
    float: none !important;
  }
  .training-reason-xs {
   word-break: break-word;
  }
  .react-player .rh5v-DefaultPlayer_video {
    width: 150px !important;
    height: unset;
  }
  .react-player .rh5v-DefaultPlayer_video {
    width: 123px !important;
    height: unset;
  }
  .react-player {
    width: 123px !important;
    height: unset;
    background: transparent !important;
  }
  .rh5v-Overlay_inner {
    left:100% !important;
    top: 44% !important;
  }
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .training-reason-xs {
    word-break: break-word;
  }
  .training-reason-lg {
    display:none;
  }
  
}
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ 
  .training-reason-xs {
    word-break: break-word;
  }
  .training-reason-lg {
    display:none;
  }
  .react-player .rh5v-DefaultPlayer_video {
    width: 123px !important;
    height: unset;
  }
  .react-player {
    width: 123px !important;
    height: unset;
    background: transparent !important;
  }
  .rh5v-Overlay_inner {
    left:60% !important;
    top: 28% !important;
  }
}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
  .training-reason-xs {
    word-break: break-word;
  }
  .training-reason-lg {
    display:none;
  }
}
@media (min-width:768px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
  .hidden-lg{
    display: none;
  }
}
@media (min-width:1024px) { /* big landscape tablets, laptops, and desktops */
  .hidden-lg{
    display: none;
  }
  .training-reason-xs {
    word-break: break-word;
    display:none;
  }
  .training-reason-lg {
    display:block;
    width: 30vw;
  } 
  .react-player .rh5v-DefaultPlayer_video {
    width: 180px !important;
    height: unset;
  }
  .react-player {
    width: 180px !important;
    height: 100px !important;
    background: transparent !important;
  }
  .rh5v-Overlay_inner {
    left:55% !important;
    top: 50% !important;
  }
}
@media (min-width:1281px) { /* hi-res laptops and desktops */ 
  .hidden-lg{
    display: none;
  }
  .training-reason-xs {
    word-break: break-word;
    display:none;
  }
  .training-reason-lg {
    display:block;
    width: 30vw;
  }
  .react-player .rh5v-DefaultPlayer_video {
    width: 220px !important;
    height: 140px !important;
  }
  .react-player {
    width: 220px !important;
    height: 140px !important;
    background: transparent !important;
  }
  .rh5v-Overlay_inner {
    left:55% !important;
    top: 50% !important;
  }
}
@media only screen and (max-width:767px)
  {
  .hidden-xs {
  display: none;
  }
  .accordion .card-header {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .text-right {
    text-align: left !important;
}
.center-xs {
  float: none !important;
}
.training-reason-xs {
  word-break: break-word;
}
.training-reason-lg {
  display:none;
}
.auth0-lock-cred-pane-internal-wrapper{
  height: 100% !important;
}
.react-player .rh5v-DefaultPlayer_video {
  width: 220px !important;
  height: unset;
}
}
.custom-switch {
  padding-left: 2.25rem;
  padding-bottom: 1rem; // added for positioning
}

.custom-control-label { // added for alignment with the switch
  padding-top: 0.4rem;
  padding-left: 2rem;
  padding-bottom: 0.1rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  height: 2rem;
  width: 3.5rem;    // it was 1.75rem before. Sliding way is longer than before.
  pointer-events: all;
  border-radius: 1rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(2rem - 4px);   // it was calc(1rem - 4px) before. Oval is bigger than before.
  height: calc(2rem - 4px);  // it was calc(1rem - 4px) before. Oval is bigger than before.
  background-color: #adb5bd;
  border-radius: 2rem; //   it was 0.5rem before. Oval is bigger than before.
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(1.5rem); //translateX(0.75rem);
  transform: translateX(1.5rem); //translateX(0.75rem);
}

.centered-td {
  text-align: center !important;
  vertical-align: middle !important;
}
.bold-border-2 {
  border: 2px solid !important
}
.border-2 {
  border: 2px solid rgba(0, 0, 0, 0.125) !important
}
.primary-border-2 {
  border: 2px solid $primary !important
}
.badge-status{
  border-radius: 50px !important;
  padding: 8px 16px 8px 16px !important;
}
.badge-patient{
  border-radius: 50px !important;
  padding: 12px !important;
}
.badge-bg-warning{
  background: rgba(255, 204, 74, 0.2) !important;
  color: #FFD057 !important;
}
.badge-bg-success{
  background: rgba(40, 167, 69, 0.2) !important;
  color: #28A745 !important;
}
.badge-bg-secondary{
  background: rgba(112, 112, 137, 0.2) !important;
  color: #707089 !important;
}
.badge-bg-info{
  background:rgba(27, 186, 186, 0.2) !important;
  color: #1BBABA !important;
}
.badge-bg-danger{
  background: rgba(255, 105, 95, 0.2) !important;
  color:#FF695F !important;
}
.badge-tag {
  padding-top: 0.6em;
    padding-bottom: 0.6em;
    background-color: transparent;
    border-radius: 10px;
    border:1px solid $primary;
    color: $primary;
    font-size: 80%;
    font-weight: 500;
  .close {
    padding-right: 0.4em;
    padding-left: 0.4em;
    font-size: inherit;
    margin-right: -0.4em;
    color: inherit;
    text-shadow: none;
    opacity: 1.0 !important;
    font-weight: 700 !important;
  }
}
.close-icon {
  float: none !important;
  padding-right: 0.4em;
  padding-left: 0.4em !important;
  font-size: inherit;
  margin-right: -0.4em;
  color: inherit;
  text-shadow: none;
  opacity: 1.0 !important;
  font-weight: 700 !important;
}
.time-input-small{
  max-width: 100px !important;
}
.w-10 { width:10% !important; vertical-align:middle !important}
.w-30 { width:30% !important; vertical-align:middle !important}
.w-150 { width:150px !important; vertical-align:middle !important}
.w-105 { width:105px !important; vertical-align:middle !important}
.w-140 { width:140px !important; vertical-align:middle !important}
.rbt-token {
  background-color: #ff695f;
  color: #ffffff;
}

.error-msg {
  color: #ff695f;
  font-size: 1rem;
}
.accordion .btn-link {
   color:#000;
   padding:0px !important;
}
.popup-btn{
  position: fixed;
  top: 60%;
  right: 0px;
  width: 82px;
  height: 0px;
  text-align:right;
  z-index:9999;
    margin-top:-15px;
    background-color: #FF695F;
    border-radius: 10px 10px 0px 0px;
}

.popup-btn a{
    transform: rotate(-90deg);
				-webkit-transform: rotate(-90deg); 
				-moz-transform: rotate(-90deg); 
				-o-transform: rotate(-90deg); 
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    display: block; 
    background-color: #FF695F;
    text-align:center;
    width: 130px;
    height: 42px;
    color: #fff; 
    text-decoration: none;
    border-radius: 10px 10px 0px 0px;
    font-family: Monserat, Helvetica, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
        
}

.popup-btn a:hover { 
  background-color: #FF695F;
  color: #fff; 
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .popup-btn{
    right: 0px !important;
  }
 }

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .popup-btn{
    right: 0px !important;
  }
 }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  .popup-btn{
    right: 0px !important;
  }
}

.ql-editor{
  max-height: 150px !important;
}

#create-appointment-form .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  background-color: $primary;
  color: #fff;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-color: $primary;
}
#create-appointment-form .nav-tabs .nav-link, .nav-tabs .nav-item.show .nav-link{
  border-color: $primary;
  color:#000;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.hideDateOverlay .DayPicker-wrapper{
  display: none !important;
}
.dropdown-time {
  cursor:pointer;
  button{
    padding-bottom: 0px;
    padding-top: 0px;
    vertical-align: top;
    border-radius: 0px;
  }
  a{
    font-size: 1.6rem;
    vertical-align: top;
  }
  
}
.btn-dd-normal{
  padding-bottom: 0px;
    padding-top: 0px;
    vertical-align: top;
    border-radius: 0px;
}

.exerciseInValid {
  .form-control:invalid, .form-control.is-invalid {
    border-color: #ffc107 !important;
  }
}
.exerciseInError {
  .form-control:invalid, .form-control.is-invalid {
    border-color: #dc3545 !important;
 
  }
}
.DayPicker {
  display: inline-block;
  font-size: 1.2rem;
}

.rh5v-Volume_icon {
  width: 32px !important;
  height: 32px !important;
}
.rh5v-PlayPause_icon{
  width: 32px !important;
  height: 32px !important;
}
.ql-container {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.pointer{
  cursor: pointer;
}
.clear {
  clear: both;
}
.is-breakable {
  word-break: break-word;
}
.icon-enlivio_heart:before {
  content: "\e900";
  color: #19baba;
}
.no-y-overflow{
  overflow-y: hidden !important;
}
.no-x-overflow{
  overflow-x: hidden !important;
}
.no-y-overflow::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}
.no-x-overflow::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}
.py-3 {
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
}
.px-4 {
  padding-right: 1.5rem!important;
  padding-left: 1.5rem!important;
}
.flex-grow-0 {
  flex-grow: 0!important;
}
.border-top {
  border-top: 1px solid #dee2e6!important;
}

.container-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.steps{
  overflow-x:hidden !important;
}
.step-content{
  padding-right: 1.8rem !important;
}
.step-circle-custom {
  border: 2px solid #A0A3B1 !important;
  color: #A0A3B1 !important;
}
.step-circle-active {
  border: 2px solid #FF695F !important;
  color: #fff !important;
  background-color: #FF695F !important;
}
.step-text-custom{
  color: #000000 !important;
}

.plan-trial-heading {
  background: #1BBABA;
  border-radius: 12px 12px 0px 0px !important;
  color: #F0F0F3;
  font-weight: bold !important;
  min-width: 250px;
} 
.pricing-card-title{
  color: #FF695F;
}
.price-list{
  list-style-type: '\2713';
  word-wrap: break-word;
  text-align: left !important;
  line-height: 30px;
  min-height: 150px;
}
.plan-price-card{
  box-shadow:1px 1px 8px rgba(100, 141, 141, 0.25);
  border-radius: 12px 12px 12px 12px !important;
}
.plan-price-card-account{
  box-shadow:1px 1px 8px rgba(100, 141, 141, 0.25);
  border-radius: 12px 12px 12px 12px !important;
}
.plan-price-card:hover{
  background: #f3f5f5;
}
.underline-link:hover{
  text-decoration: underline;
}
.plan-price-card-footer{
background-color: transparent;
}
.no-event {
  pointer-events: none;
}
.custom-modal {
  color: #000000;
  .modal-content {
    border-radius: 0.8rem;
    padding: 1rem;
  }
  .modal-title {
    font-size: 2.6rem;
    font-weight: 700;
  }
  .modal-header {
    border: none;
    .close {
      font-size: 3rem;
      font-weight: 300;
      color: inherit;
    }
  }
  .modal-body {
    strong {
      color: #000000;
    }
  }
  .modal-footer {
    border-top: none;
  }

  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .give-permission-img {
      display: none;
    }
  }
}

.table-patient {
  font-family: Montserrat, Helvetica, serif;
  font-size: 1.7rem;
  color:#161616 !important;
  .patientName{
   line-height: 20px;
   font-weight: 400 !important;
  }
  .fontGray{
    color:#A0A3B1 !important;
  }
  thead th{
    cursor: pointer;
    font-weight: normal;
    color:#A0A3B1;
    font-weight: 400 !important;
  }
  th {
    cursor: pointer;
    padding:14px !important;
    vertical-align:middle !important;
    font-weight: normal;
  }
  w-30{
    min-width: 30%;
    max-width: 30%;
  }
}
@import 'chat.scss';